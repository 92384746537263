import React from "react";
import PropTypes from "prop-types";
import { useLanguage } from "../../../hooks/useLanguage";

import {
  ContentWrapper,
  SectionDefault,
  StikyGrid,
  StikyGridColumn,
} from "../../Ui/InterfaceSystem/InterfaceSystem";
import SectionHeaderMain from "../../Ui/SectionHeaderMain/SectionHeaderMain";
// import AboutAside from './AboutAside/AboutAside';
// import AboutItems from './AboutItems/AboutItems';
import AsideDefault from "../../Ui/AsideDefault/AsideDefault";
import ServicesInnerAside from "./ServicesInnerAside/ServicesInnerAside";
import ServicesInfo from "./ServicesInfo/ServicesInfo";

const ServicesInner = ({ service, whatsApp }) => {
  return (
    <SectionDefault classes={"service-inner__page"}>
      <ContentWrapper>
        <SectionHeaderMain
          title={useLanguage(
            service.main.title_ua,
            service.main.title_en,
            service.main.title_de,
            service.main.title_ru
          )}
        />
        <StikyGrid>
          <StikyGridColumn>
            <AsideDefault offsetTop={140}>
              <ServicesInnerAside
                img={service?.image?.localFile}
                whatsApp={whatsApp}
                alt={useLanguage(
                  service.title_ua,
                  service.title_en,
                  service.title_de,
                  service.title_ru
                )}
              />
            </AsideDefault>
          </StikyGridColumn>
          <StikyGridColumn>
            <ServicesInfo info={service} whatsApp={whatsApp} />
          </StikyGridColumn>
        </StikyGrid>
      </ContentWrapper>
    </SectionDefault>
  );
};

ServicesInner.propTypes = {
  service: PropTypes.object,
  whatsApp: PropTypes.string,
};

export default ServicesInner;
